/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordingPersona
 */
export interface RecordingPersona {
    /**
     * 
     * @type {string}
     * @memberof RecordingPersona
     */
    stageName: string;
}

/**
 * Check if a given object implements the RecordingPersona interface.
 */
export function instanceOfRecordingPersona(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stageName" in value;

    return isInstance;
}

export function RecordingPersonaFromJSON(json: any): RecordingPersona {
    return RecordingPersonaFromJSONTyped(json, false);
}

export function RecordingPersonaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingPersona {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stageName': json['stageName'],
    };
}

export function RecordingPersonaToJSON(value?: RecordingPersona | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stageName': value.stageName,
    };
}

