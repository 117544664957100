import React, {useState} from "react";

const Spinner: React.FC = () => {
  return (
    <div className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50">
      <div className="tw-w-16 tw-h-16 tw-border-4 tw-border-t-transparent tw-border-white tw-rounded-full tw-animate-spin"></div>
    </div>
  );
};



export const useSpinner = () => {
  const [isVisible, setIsVisible] = useState(false);

  const showSpinner = () => setIsVisible(true);
  const hideSpinner = () => setIsVisible(false);

  const WSpinner = () =>
    isVisible ? (
      <Spinner />
    ) : null;

  return { showSpinner, hideSpinner, WSpinner };
};