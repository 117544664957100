/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordingPplContributionsInner
 */
export interface RecordingPplContributionsInner {
    /**
     * 
     * @type {string}
     * @memberof RecordingPplContributionsInner
     */
    claimStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingPplContributionsInner
     */
    claimSubstatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingPplContributionsInner
     */
    claimCaseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingPplContributionsInner
     */
    westburyStatus?: RecordingPplContributionsInnerWestburyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RecordingPplContributionsInner
     */
    contribution: string;
    /**
     * 
     * @type {string}
     * @memberof RecordingPplContributionsInner
     */
    westburyContribution: string;
}


/**
 * @export
 */
export const RecordingPplContributionsInnerWestburyStatusEnum = {
    SubmittedToSociety: 'SUBMITTED_TO_SOCIETY',
    EvidenceNeeded: 'EVIDENCE_NEEDED',
    RegisteredWithSociety: 'REGISTERED_WITH_SOCIETY',
    ToBeRegistered: 'TO_BE_REGISTERED',
    Other: 'OTHER'
} as const;
export type RecordingPplContributionsInnerWestburyStatusEnum = typeof RecordingPplContributionsInnerWestburyStatusEnum[keyof typeof RecordingPplContributionsInnerWestburyStatusEnum];


/**
 * Check if a given object implements the RecordingPplContributionsInner interface.
 */
export function instanceOfRecordingPplContributionsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contribution" in value;
    isInstance = isInstance && "westburyContribution" in value;

    return isInstance;
}

export function RecordingPplContributionsInnerFromJSON(json: any): RecordingPplContributionsInner {
    return RecordingPplContributionsInnerFromJSONTyped(json, false);
}

export function RecordingPplContributionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingPplContributionsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'claimStatus': !exists(json, 'claimStatus') ? undefined : json['claimStatus'],
        'claimSubstatus': !exists(json, 'claimSubstatus') ? undefined : json['claimSubstatus'],
        'claimCaseNumber': !exists(json, 'claimCaseNumber') ? undefined : json['claimCaseNumber'],
        'westburyStatus': !exists(json, 'westburyStatus') ? undefined : json['westburyStatus'],
        'contribution': json['contribution'],
        'westburyContribution': json['westburyContribution'],
    };
}

export function RecordingPplContributionsInnerToJSON(value?: RecordingPplContributionsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'claimStatus': value.claimStatus,
        'claimSubstatus': value.claimSubstatus,
        'claimCaseNumber': value.claimCaseNumber,
        'westburyStatus': value.westburyStatus,
        'contribution': value.contribution,
        'westburyContribution': value.westburyContribution,
    };
}

