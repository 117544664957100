/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordingEarliestParentReleaseEvidenceImagesInner } from './RecordingEarliestParentReleaseEvidenceImagesInner';
import {
    RecordingEarliestParentReleaseEvidenceImagesInnerFromJSON,
    RecordingEarliestParentReleaseEvidenceImagesInnerFromJSONTyped,
    RecordingEarliestParentReleaseEvidenceImagesInnerToJSON,
} from './RecordingEarliestParentReleaseEvidenceImagesInner';

/**
 * 
 * @export
 * @interface RecordingEvidenceReleasesInner
 */
export interface RecordingEvidenceReleasesInner {
    /**
     * 
     * @type {string}
     * @memberof RecordingEvidenceReleasesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RecordingEvidenceReleasesInner
     */
    sourceReleaseId: string;
    /**
     * 
     * @type {Array<RecordingEarliestParentReleaseEvidenceImagesInner>}
     * @memberof RecordingEvidenceReleasesInner
     */
    evidenceImages: Array<RecordingEarliestParentReleaseEvidenceImagesInner>;
}

/**
 * Check if a given object implements the RecordingEvidenceReleasesInner interface.
 */
export function instanceOfRecordingEvidenceReleasesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceReleaseId" in value;
    isInstance = isInstance && "evidenceImages" in value;

    return isInstance;
}

export function RecordingEvidenceReleasesInnerFromJSON(json: any): RecordingEvidenceReleasesInner {
    return RecordingEvidenceReleasesInnerFromJSONTyped(json, false);
}

export function RecordingEvidenceReleasesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingEvidenceReleasesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceReleaseId': json['sourceReleaseId'],
        'evidenceImages': ((json['evidenceImages'] as Array<any>).map(RecordingEarliestParentReleaseEvidenceImagesInnerFromJSON)),
    };
}

export function RecordingEvidenceReleasesInnerToJSON(value?: RecordingEvidenceReleasesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceReleaseId': value.sourceReleaseId,
        'evidenceImages': ((value.evidenceImages as Array<any>).map(RecordingEarliestParentReleaseEvidenceImagesInnerToJSON)),
    };
}

