/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordingEarliestParentRelease } from './RecordingEarliestParentRelease';
import {
    RecordingEarliestParentReleaseFromJSON,
    RecordingEarliestParentReleaseFromJSONTyped,
    RecordingEarliestParentReleaseToJSON,
} from './RecordingEarliestParentRelease';
import type { RecordingEvidenceReleasesInner } from './RecordingEvidenceReleasesInner';
import {
    RecordingEvidenceReleasesInnerFromJSON,
    RecordingEvidenceReleasesInnerFromJSONTyped,
    RecordingEvidenceReleasesInnerToJSON,
} from './RecordingEvidenceReleasesInner';
import type { RecordingParentRecording } from './RecordingParentRecording';
import {
    RecordingParentRecordingFromJSON,
    RecordingParentRecordingFromJSONTyped,
    RecordingParentRecordingToJSON,
} from './RecordingParentRecording';
import type { RecordingPerformance } from './RecordingPerformance';
import {
    RecordingPerformanceFromJSON,
    RecordingPerformanceFromJSONTyped,
    RecordingPerformanceToJSON,
} from './RecordingPerformance';
import type { RecordingPersona } from './RecordingPersona';
import {
    RecordingPersonaFromJSON,
    RecordingPersonaFromJSONTyped,
    RecordingPersonaToJSON,
} from './RecordingPersona';
import type { RecordingPplContributionsInner } from './RecordingPplContributionsInner';
import {
    RecordingPplContributionsInnerFromJSON,
    RecordingPplContributionsInnerFromJSONTyped,
    RecordingPplContributionsInnerToJSON,
} from './RecordingPplContributionsInner';
import type { RecordingThumbnailEvidenceImage } from './RecordingThumbnailEvidenceImage';
import {
    RecordingThumbnailEvidenceImageFromJSON,
    RecordingThumbnailEvidenceImageFromJSONTyped,
    RecordingThumbnailEvidenceImageToJSON,
} from './RecordingThumbnailEvidenceImage';
import type { RecordingWestburyContributionsInner } from './RecordingWestburyContributionsInner';
import {
    RecordingWestburyContributionsInnerFromJSON,
    RecordingWestburyContributionsInnerFromJSONTyped,
    RecordingWestburyContributionsInnerToJSON,
} from './RecordingWestburyContributionsInner';

/**
 * 
 * @export
 * @interface PrimaryDiscographyRecordingsInner
 */
export interface PrimaryDiscographyRecordingsInner {
    /**
     * 
     * @type {RecordingParentRecording}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    parentRecording: RecordingParentRecording;
    /**
     * 
     * @type {Array<RecordingWestburyContributionsInner>}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    westburyContributions: Array<RecordingWestburyContributionsInner>;
    /**
     * 
     * @type {Array<RecordingPplContributionsInner>}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    pplContributions?: Array<RecordingPplContributionsInner> | null;
    /**
     * 
     * @type {RecordingPersona}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    persona: RecordingPersona;
    /**
     * 
     * @type {string}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    readonly person?: string;
    /**
     * 
     * @type {RecordingPerformance}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    performance: RecordingPerformance;
    /**
     * 
     * @type {RecordingEarliestParentRelease}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    earliestParentRelease?: RecordingEarliestParentRelease | null;
    /**
     * 
     * @type {Array<RecordingEvidenceReleasesInner>}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    evidenceReleases?: Array<RecordingEvidenceReleasesInner> | null;
    /**
     * 
     * @type {RecordingThumbnailEvidenceImage}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    thumbnailEvidenceImage?: RecordingThumbnailEvidenceImage | null;
}

/**
 * Check if a given object implements the PrimaryDiscographyRecordingsInner interface.
 */
export function instanceOfPrimaryDiscographyRecordingsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentRecording" in value;
    isInstance = isInstance && "westburyContributions" in value;
    isInstance = isInstance && "persona" in value;
    isInstance = isInstance && "performance" in value;

    return isInstance;
}

export function PrimaryDiscographyRecordingsInnerFromJSON(json: any): PrimaryDiscographyRecordingsInner {
    return PrimaryDiscographyRecordingsInnerFromJSONTyped(json, false);
}

export function PrimaryDiscographyRecordingsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscographyRecordingsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentRecording': RecordingParentRecordingFromJSON(json['parentRecording']),
        'westburyContributions': ((json['westburyContributions'] as Array<any>).map(RecordingWestburyContributionsInnerFromJSON)),
        'pplContributions': !exists(json, 'pplContributions') ? undefined : (json['pplContributions'] === null ? null : (json['pplContributions'] as Array<any>).map(RecordingPplContributionsInnerFromJSON)),
        'persona': RecordingPersonaFromJSON(json['persona']),
        'person': !exists(json, 'person') ? undefined : json['person'],
        'performance': RecordingPerformanceFromJSON(json['performance']),
        'earliestParentRelease': !exists(json, 'earliestParentRelease') ? undefined : RecordingEarliestParentReleaseFromJSON(json['earliestParentRelease']),
        'evidenceReleases': !exists(json, 'evidenceReleases') ? undefined : (json['evidenceReleases'] === null ? null : (json['evidenceReleases'] as Array<any>).map(RecordingEvidenceReleasesInnerFromJSON)),
        'thumbnailEvidenceImage': !exists(json, 'thumbnailEvidenceImage') ? undefined : RecordingThumbnailEvidenceImageFromJSON(json['thumbnailEvidenceImage']),
    };
}

export function PrimaryDiscographyRecordingsInnerToJSON(value?: PrimaryDiscographyRecordingsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentRecording': RecordingParentRecordingToJSON(value.parentRecording),
        'westburyContributions': ((value.westburyContributions as Array<any>).map(RecordingWestburyContributionsInnerToJSON)),
        'pplContributions': value.pplContributions === undefined ? undefined : (value.pplContributions === null ? null : (value.pplContributions as Array<any>).map(RecordingPplContributionsInnerToJSON)),
        'persona': RecordingPersonaToJSON(value.persona),
        'performance': RecordingPerformanceToJSON(value.performance),
        'earliestParentRelease': RecordingEarliestParentReleaseToJSON(value.earliestParentRelease),
        'evidenceReleases': value.evidenceReleases === undefined ? undefined : (value.evidenceReleases === null ? null : (value.evidenceReleases as Array<any>).map(RecordingEvidenceReleasesInnerToJSON)),
        'thumbnailEvidenceImage': RecordingThumbnailEvidenceImageToJSON(value.thumbnailEvidenceImage),
    };
}

