/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordingParentRecording
 */
export interface RecordingParentRecording {
    /**
     * 
     * @type {string}
     * @memberof RecordingParentRecording
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RecordingParentRecording
     */
    recordingTitle: string;
    /**
     * 
     * @type {string}
     * @memberof RecordingParentRecording
     */
    duration?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingParentRecording
     */
    mainArtists?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingParentRecording
     */
    isrcs?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecordingParentRecording
     */
    live: boolean;
    /**
     * 
     * @type {string}
     * @memberof RecordingParentRecording
     */
    countryOfRecording?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingParentRecording
     */
    source: Array<string>;
}

/**
 * Check if a given object implements the RecordingParentRecording interface.
 */
export function instanceOfRecordingParentRecording(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "recordingTitle" in value;
    isInstance = isInstance && "live" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function RecordingParentRecordingFromJSON(json: any): RecordingParentRecording {
    return RecordingParentRecordingFromJSONTyped(json, false);
}

export function RecordingParentRecordingFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingParentRecording {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'recordingTitle': json['recordingTitle'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'mainArtists': !exists(json, 'mainArtists') ? undefined : json['mainArtists'],
        'isrcs': !exists(json, 'isrcs') ? undefined : json['isrcs'],
        'live': json['live'],
        'countryOfRecording': !exists(json, 'countryOfRecording') ? undefined : json['countryOfRecording'],
        'source': json['source'],
    };
}

export function RecordingParentRecordingToJSON(value?: RecordingParentRecording | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordingTitle': value.recordingTitle,
        'duration': value.duration,
        'mainArtists': value.mainArtists,
        'isrcs': value.isrcs,
        'live': value.live,
        'countryOfRecording': value.countryOfRecording,
        'source': value.source,
    };
}

