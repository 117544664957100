/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrimaryDiscographyRequest
 */
export interface PrimaryDiscographyRequest {
    /**
     * 
     * @type {number}
     * @memberof PrimaryDiscographyRequest
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof PrimaryDiscographyRequest
     */
    sortField: PrimaryDiscographyRequestSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PrimaryDiscographyRequest
     */
    sortOrder: PrimaryDiscographyRequestSortOrderEnum;
    /**
     * 
     * @type {Array<any>}
     * @memberof PrimaryDiscographyRequest
     */
    searchAfter?: Array<any>;
}


/**
 * @export
 */
export const PrimaryDiscographyRequestSortFieldEnum = {
    ParentRecordingMainArtists: 'parentRecording.mainArtists',
    ParentRecordingRecordingTitle: 'parentRecording.recordingTitle',
    EarliestParentReleaseReleaseTitle: 'earliestParentRelease.releaseTitle',
    EarliestParentReleaseDateReleased: 'earliestParentRelease.dateReleased',
    EarliestParentReleaseMediaType: 'earliestParentRelease.mediaType',
    EarliestParentReleaseFormat: 'earliestParentRelease.format',
    PersonaStageName: 'persona.stageName'
} as const;
export type PrimaryDiscographyRequestSortFieldEnum = typeof PrimaryDiscographyRequestSortFieldEnum[keyof typeof PrimaryDiscographyRequestSortFieldEnum];

/**
 * @export
 */
export const PrimaryDiscographyRequestSortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type PrimaryDiscographyRequestSortOrderEnum = typeof PrimaryDiscographyRequestSortOrderEnum[keyof typeof PrimaryDiscographyRequestSortOrderEnum];


/**
 * Check if a given object implements the PrimaryDiscographyRequest interface.
 */
export function instanceOfPrimaryDiscographyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "sortField" in value;
    isInstance = isInstance && "sortOrder" in value;

    return isInstance;
}

export function PrimaryDiscographyRequestFromJSON(json: any): PrimaryDiscographyRequest {
    return PrimaryDiscographyRequestFromJSONTyped(json, false);
}

export function PrimaryDiscographyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscographyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': json['size'],
        'sortField': json['sort_field'],
        'sortOrder': json['sort_order'],
        'searchAfter': !exists(json, 'search_after') ? undefined : json['search_after'],
    };
}

export function PrimaryDiscographyRequestToJSON(value?: PrimaryDiscographyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'sort_field': value.sortField,
        'sort_order': value.sortOrder,
        'search_after': value.searchAfter,
    };
}

