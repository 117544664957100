/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordingEarliestParentReleaseEvidenceImagesInner } from './RecordingEarliestParentReleaseEvidenceImagesInner';
import {
    RecordingEarliestParentReleaseEvidenceImagesInnerFromJSON,
    RecordingEarliestParentReleaseEvidenceImagesInnerFromJSONTyped,
    RecordingEarliestParentReleaseEvidenceImagesInnerToJSON,
} from './RecordingEarliestParentReleaseEvidenceImagesInner';

/**
 * 
 * @export
 * @interface RecordingEarliestParentRelease
 */
export interface RecordingEarliestParentRelease {
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingEarliestParentRelease
     */
    sourceReleaseId: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    dateReleased?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    releaseTitle: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingEarliestParentRelease
     */
    releaseArtists?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    mediaType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    format?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingEarliestParentRelease
     */
    catNumbers?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordingEarliestParentRelease
     */
    releaseLabels?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    ean?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentRelease
     */
    upc?: string | null;
    /**
     * 
     * @type {Array<RecordingEarliestParentReleaseEvidenceImagesInner>}
     * @memberof RecordingEarliestParentRelease
     */
    evidenceImages?: Array<RecordingEarliestParentReleaseEvidenceImagesInner> | null;
}

/**
 * Check if a given object implements the RecordingEarliestParentRelease interface.
 */
export function instanceOfRecordingEarliestParentRelease(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceReleaseId" in value;
    isInstance = isInstance && "releaseTitle" in value;

    return isInstance;
}

export function RecordingEarliestParentReleaseFromJSON(json: any): RecordingEarliestParentRelease {
    return RecordingEarliestParentReleaseFromJSONTyped(json, false);
}

export function RecordingEarliestParentReleaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingEarliestParentRelease {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceReleaseId': json['sourceReleaseId'],
        'dateReleased': !exists(json, 'dateReleased') ? undefined : json['dateReleased'],
        'releaseTitle': json['releaseTitle'],
        'releaseArtists': !exists(json, 'releaseArtists') ? undefined : json['releaseArtists'],
        'mediaType': !exists(json, 'mediaType') ? undefined : json['mediaType'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'catNumbers': !exists(json, 'catNumbers') ? undefined : json['catNumbers'],
        'releaseLabels': !exists(json, 'releaseLabels') ? undefined : json['releaseLabels'],
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'upc': !exists(json, 'upc') ? undefined : json['upc'],
        'evidenceImages': !exists(json, 'evidenceImages') ? undefined : (json['evidenceImages'] === null ? null : (json['evidenceImages'] as Array<any>).map(RecordingEarliestParentReleaseEvidenceImagesInnerFromJSON)),
    };
}

export function RecordingEarliestParentReleaseToJSON(value?: RecordingEarliestParentRelease | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceReleaseId': value.sourceReleaseId,
        'dateReleased': value.dateReleased,
        'releaseTitle': value.releaseTitle,
        'releaseArtists': value.releaseArtists,
        'mediaType': value.mediaType,
        'format': value.format,
        'catNumbers': value.catNumbers,
        'releaseLabels': value.releaseLabels,
        'ean': value.ean,
        'upc': value.upc,
        'evidenceImages': value.evidenceImages === undefined ? undefined : (value.evidenceImages === null ? null : (value.evidenceImages as Array<any>).map(RecordingEarliestParentReleaseEvidenceImagesInnerToJSON)),
    };
}

