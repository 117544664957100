import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  placeholderRips,
  placeholderSocDubs,
  placeholderPlayedRecs,
} from './ArtistDiscographyPlaceholders';
import { useClientId } from 'shared_components/context/client';
import WContainer from 'shared_components/components/WContainer/WContainer';
import { WAccordion } from 'shared_components/components/WAccordion/WAccordion';
import { WTable } from 'shared_components/WTable/WTable';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { SocietyDiscographyTable } from './SecondaryDiscographyTable';
function ArtistDiscography() {
  const { clientId } = useClientId();
  const [isEditing, setIsEditing] = useState<boolean | number>(false);
  const [socRips, setsocRips] = useState(0);
  const [socDioscSub, setsocDioscSub] = useState(0);
  const [socRipsList, setSocRipsList] = useState(placeholderRips);
  const [socDiscSub, setSocDiscSub] = useState(placeholderSocDubs);

  const saveDetails = (e: any, index: number) => {
    const soc = e.target.getAttribute('dataSocd');
    const file = e.target.getAttribute('dataFile');
    const date = e.target.getAttribute('dataDate');
    const data = {
      soc,
      file,
      date,
    };
    // API call to save the data
    setIsEditing(false);
  };

  const socRipsListData = socRipsList.map((societyLog, index) => ({
    ...societyLog,
    date: <div>{societyLog.date}</div>,
    file: (
      <div>
        <a
          href={societyLog.file}
          className="fileLink"
          target="_blank"
          rel="noreferrer"
        >
          {societyLog.file}
        </a>
      </div>
    ),
  }));

  const societyLogData = socDiscSub.map((societyLog, index) => ({
    ...societyLog,
    date: <div className="dateReceived">{societyLog.date}</div>,
    file: (
      <div className="fileLinkCol">
        <a
          href={societyLog.file}
          className="fileLink"
          target="_blank"
          rel="noreferrer"
        >
          {societyLog.file}
        </a>
      </div>
    ),
    fileEditMode: (
      <div className="fileEditMode">
        <div className="actionButtons">
          <WButton
            variant="secondary"
            label="Edit"
            icon="edit"
            onClick={() => setIsEditing(index)}
          />
        </div>
      </div>
    ),
    fileSendButton: (
      <div className="fileSendButton">
        <div className="portLink">
          <button className="send-to-btn btn">Send to society</button>
        </div>
      </div>
    ),
  }));

  return (
    <div className="tw-w-3/4">
      <WContainer>
        <Link
          to={`/discography/${clientId}`}
          state={{ artist_name: clientId }}
          className=""
        >
          <div className="tw-flex tw-justify-between tw-text-xl tw-items-center">
            <span className="">Discography</span>
            <i className="wi tw-h-[40px] tw-w-[40px] wi-chevron-right"></i>
          </div>
        </Link>
      </WContainer>
      <WContainer>
        <Link to={`/discography-registrations/${clientId}`}>
          <div className="tw-flex tw-justify-between tw-text-xl tw-items-center">
            <span className="">Registrations</span>
            <i className="wi tw-h-[40px] tw-w-[40px] wi-chevron-right"></i>
          </div>
        </Link>
      </WContainer>
      <WAccordion title="Society Rips" disabled>
        <WTable
          columns={[
            { title: 'Society', width: '1fr', dataKey: 'soc' },
            { title: 'Date received', width: '1fr', dataKey: 'date' },
            { title: 'File', width: '1fr', dataKey: 'file' },
          ]}
          data={socRipsListData}
        ></WTable>
      </WAccordion>
      <WAccordion title="Society Discography Submissions">
        <SocietyDiscographyTable clientId={clientId} />
      </WAccordion>
    </div>
  );
}

export default ArtistDiscography;
