/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordingEarliestParentReleaseEvidenceImagesInner
 */
export interface RecordingEarliestParentReleaseEvidenceImagesInner {
    /**
     * 
     * @type {string}
     * @memberof RecordingEarliestParentReleaseEvidenceImagesInner
     */
    objectKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof RecordingEarliestParentReleaseEvidenceImagesInner
     */
    isCover: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecordingEarliestParentReleaseEvidenceImagesInner
     */
    isPreferred: boolean;
}

/**
 * Check if a given object implements the RecordingEarliestParentReleaseEvidenceImagesInner interface.
 */
export function instanceOfRecordingEarliestParentReleaseEvidenceImagesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "objectKey" in value;
    isInstance = isInstance && "isCover" in value;
    isInstance = isInstance && "isPreferred" in value;

    return isInstance;
}

export function RecordingEarliestParentReleaseEvidenceImagesInnerFromJSON(json: any): RecordingEarliestParentReleaseEvidenceImagesInner {
    return RecordingEarliestParentReleaseEvidenceImagesInnerFromJSONTyped(json, false);
}

export function RecordingEarliestParentReleaseEvidenceImagesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingEarliestParentReleaseEvidenceImagesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectKey': json['objectKey'],
        'isCover': json['isCover'],
        'isPreferred': json['isPreferred'],
    };
}

export function RecordingEarliestParentReleaseEvidenceImagesInnerToJSON(value?: RecordingEarliestParentReleaseEvidenceImagesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectKey': value.objectKey,
        'isCover': value.isCover,
        'isPreferred': value.isPreferred,
    };
}

