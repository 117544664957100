import React from 'react';
import posthog from 'posthog-js';

import { WToaster } from 'shared_components/utils/Toast';
// import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminLogin from './view/PageLayout/AdminLogin';
import Dashboard from './view/PageLayout/Dashboard';
import Clients from './view/PageLayout/Clients';
import Discography from './view/PageLayout/Discography';
import Distribution from './view/PageLayout/Distribution';
import TermsOfAgreement from './view/PageLayout/TermsOfAgreement';

import MasterDiscography from './view/PageLayout/MasterDiscography';
import DiscographyRegistrations from './view/PageLayout/DiscographyRegistrations';
import AIEvidence from './view/PageLayout/AIEvidence';
import Settings from './view/PageLayout/Components/Settings/Settings';
import SocietyConfig from './view/PageLayout/SocietyConfig';
import { NavigationProvider } from 'shared_components/context/navigation';
import { ClientProvider } from 'shared_components/context/client';
import { ApiProvider } from 'shared_components/context';
import { UserProvider } from 'shared_components/context/user';
import './App.css';

import './global.css';
import 'shared_components/dashboard_icons.css';
import 'shared_components/icons.css';
import DiscographyNew from './view/PageLayout/DiscographyNew';
import DistributionNew from './view/PageLayout/DistributionNew';
import { useApi } from 'shared_components/context';
import SocietyForms from './view/PageLayout/SocietyForms';
import { WErrorProvider } from 'shared_components/components/WError/WErrorProvider';

// Sentry.init({
//   dsn: 'https://1516507424c330427f150253597b0248@o4506376806465536.ingest.sentry.io/4506376953659392',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'https://admin.prod.westburymusic.com/',
//         'https://admin.dev.westburymusic.com/',
//       ],
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

posthog.init('phc_PUGeiiTXxm2q7fPp5n8d77UgtXu59IZgUdbRVEVrixF', {
  api_host: 'https://eu.posthog.com',
});

function App() {
  const { adminApi: api } = useApi();

  return (
    <WErrorProvider>
      <ApiProvider>
        <UserProvider api={api}>
          <ClientProvider>
            <BrowserRouter>
              <WToaster />
              <NavigationProvider>
                <Routes>
                  <Route path="/" element={<AdminLogin />} />
                  <Route path="/signin" element={<AdminLogin />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/discography-dashboard"
                    element={<DiscographyNew />}
                  />
                  <Route
                    path="/distribution-dashboard"
                    element={<DistributionNew />}
                  />
                  <Route path="/discography" element={<Discography />} />
                  <Route path="/distribution" element={<Distribution />} />
                  <Route
                    path="/terms-of-agreement/:userId"
                    element={<TermsOfAgreement />}
                  />

                  <Route
                    path="/discography/:userId"
                    element={<MasterDiscography />}
                  />
                  <Route
                    path="/discography-registrations/:userId"
                    element={<DiscographyRegistrations />}
                  />
                  <Route
                    path="/discography/:userId/ai-evidence/:parentRecordingId"
                    element={<AIEvidence />}
                  />

                  <Route path="/settings" element={<Settings />} />
                  <Route path="/society-config" element={<SocietyConfig />} />
                  <Route
                    path="/society-forms/:userId"
                    element={<SocietyForms />}
                  />
                </Routes>
              </NavigationProvider>
            </BrowserRouter>
          </ClientProvider>
        </UserProvider>
      </ApiProvider>
    </WErrorProvider>
  );
}

export default App;
