import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import Star from 'shared_components/public/assets/rebrand-icons/star.svg';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import PageLayout from './PageLayout';
import { WError } from 'shared_components/components/WError/WError';
import { useSpinner } from 'shared_components/components/WSpinner';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { useApi } from 'shared_components/context';
import { RecordingEarliestParentReleaseEvidenceImagesInner } from 'shared_components/generated/admin';
import WModal from 'shared_components/components/WModal';
import JSZip from 'jszip';
import WCheckBox from 'shared_components/components/WCheckBox';
import WImagePreview from 'shared_components/components/WImagePreview';
type ImageKeySignedUrlMap = {
  [key: string]: string;
};

type RecordingData = {
  artist: string;
  recording: string;
  contributor: string;
  roles: string[];
};

type UrlIsCheckedMap = {
  [url: string]: boolean;
};

export default function AIEvidence() {
  const { userId, parentRecordingId } = useParams<{
    userId: string;
    parentRecordingId: string;
  }>();
  const location = useLocation();
  const { throwError } = useWError();
  const { adminApi: api } = useApi();

  const { showSpinner, hideSpinner, WSpinner } = useSpinner();
  const [urlIsCheckedMap, setUrlIsCheckedMap] = useState<UrlIsCheckedMap>({});
  const [isSelectAll, setIsSelectAll] = useState<boolean>(true);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  const [images, setImages] = useState<
    RecordingEarliestParentReleaseEvidenceImagesInner[]
  >([]);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (image: any) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };
  const [imageUrls, setImageUrls] = useState<ImageKeySignedUrlMap>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const [visibleRows, setVisibleRows] = useState<number>(2);
  const [recordingData, setRecordingData] = useState<RecordingData>({
    artist: '',
    recording: '',
    contributor: '',
    roles: [],
  });
  const ROW_SIZE = 6; // Number of images per row
  const imagesSorted = useMemo<
    RecordingEarliestParentReleaseEvidenceImagesInner[]
  >(() => {
    return images.toSorted(
      (a, b) => Number(b.isPreferred) - Number(a.isPreferred)
    );
  }, [images]);

  const carouselImages = useMemo<
    RecordingEarliestParentReleaseEvidenceImagesInner[]
  >(() => {
    if (imagesSorted.length <= 2) {
      // If there are 2 or fewer images, display all
      return imagesSorted;
    }

    const firstIndex = carouselIndex % imagesSorted.length;
    const secondIndex = (carouselIndex + 1) % imagesSorted.length;

    return [imagesSorted[firstIndex], imagesSorted[secondIndex]];
  }, [carouselIndex, imagesSorted]);

  const fetchData = async () => {
    if (userId === undefined || parentRecordingId === undefined) return;

    try {
      showSpinner();
      const {
        parentRecording,
        earliestParentRelease,
        persona,
        person,
        evidenceReleases,
        westburyContributions,
      } = await api.retrieveRecording({
        userId,
        parentRecordingId,
      });

      const roles: string[] = [];

      westburyContributions.forEach((westburyContribution) => {
        const role =
          westburyContribution.contribution.charAt(0).toUpperCase() +
          westburyContribution.contribution.slice(1);
        roles.push(role);
      });
      setRecordingData({
        artist: Array.isArray(parentRecording?.mainArtists)
          ? parentRecording.mainArtists.length > 1
            ? parentRecording.mainArtists.join(', ')
            : parentRecording.mainArtists[0]
          : '-', // fallback if not an array
        recording: parentRecording.recordingTitle,
        contributor: person.realName[0], // TODO: Change it to Legal Name via API call to Neptune
        roles: roles,
      });

      if (!evidenceReleases) return;

      const newImages: RecordingEarliestParentReleaseEvidenceImagesInner[] = [];
      const newLoadingState: Record<string, boolean> = {};

      for (const evidenceRelease of evidenceReleases) {
        for (const image of evidenceRelease.evidenceImages ?? []) {
          newImages.push(image);
          newLoadingState[image.objectKey] = true;
          // Fetch signed URL for each image
          api
            .evidenceImageSignedUrlEvidenceImageSignedUrl({
              userId,
              evidenceImageSignedUrlRequest: {
                objectKey: image.objectKey,
              },
            })
            .then(({ signedUrl }) => {
              setImageUrls((prev) => ({
                ...prev,
                [image.objectKey]: signedUrl,
              }));
            })
            .catch((error) => {
              console.error(
                `Error fetching signed URL for ${image.objectKey}: `,
                error
              );
            })
            .finally(() => {
              setLoading((prev) => ({
                ...prev,
                [image.objectKey]: false,
              }));
            });
        }
      }

      setImages(newImages);
      setLoading(newLoadingState);
    } catch (error) {
      throwError(new WError('Something went wrong'));
    } finally {
      hideSpinner();
    }
  };

  useEffect(() => {
    fetchData();
  }, [parentRecordingId]);
  // compute that at least one urlIsChecked everytime urlIsCheckedMap changes
  const isAtLeastOneChecked = useMemo(() => {
    debugger;
    const res = Object.values(urlIsCheckedMap).some((isChecked) => isChecked);

    return res;
  }, [urlIsCheckedMap]);

  const handleChecked = (url: string) => {
    setUrlIsCheckedMap({
      ...urlIsCheckedMap,
      [url]: !urlIsCheckedMap[url],
    });
  };

  useEffect(() => {
    setIsSelectAll(
      Object.values(urlIsCheckedMap).every((isChecked) => isChecked)
    );
  }, [urlIsCheckedMap]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlIsCheckedMap(
      Object.fromEntries(
        Object.entries(urlIsCheckedMap).map(([url]) => [
          url,
          event.target.checked,
        ])
      )
    );
  };

  const handleDownload = async () => {
    if (Object.keys(urlIsCheckedMap).length === 0) {
      return;
    }
    const zip = new JSZip();

    const urlsForDownload = Object.entries(urlIsCheckedMap).flatMap(
      ([url, isChecked]) => (isChecked ? [url] : [])
    );

    for await (const [index, url] of Object.entries(urlsForDownload)) {
      try {
        const imageBlob = await fetch(url).then((response) => response.blob());
        const pathComponents = new URL(url).pathname.split('/');
        const fileName = pathComponents.pop() ?? `evidence${index}.jpg`;
        const imageFile = new File([imageBlob], fileName);
        zip.file(fileName, imageFile);
      } catch (error) {
        console.error(error);
      }
    }

    zip
      .generateAsync({ type: 'blob' })
      .then((content) => saveAs(content, 'annotated-evidence'));
  };

  const handleCopyToClipboard = () => {
    if (Object.keys(urlIsCheckedMap).length === 0) {
      return;
    }
    const text = Object.entries(urlIsCheckedMap)
      .flatMap(([url, isChecked]) => (isChecked ? [url] : []))
      .join('\n');
    navigator.clipboard.writeText(text);
  };

  return (
    <PageLayout>
      <WSpinner />
      {isModalOpen && (
        <WModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={'Image'}
          modalClasses="tw-max-w-xl tw-max-h-screen tw-overflow-y-auto"
        >
          {selectedImage && (
            <img
              src={imageUrls[selectedImage.objectKey]}
              alt={selectedImage.alt}
              className="tw-w-full tw-h-auto tw-rounded-lg"
            />
          )}
        </WModal>
      )}

      <h2>AI Evidence</h2>

      <div>
        <WButton
          variant="link-secondary"
          icon="chevron-left"
          onClick={() => {
            window.history.back();
          }}
          label="Back"
        />
      </div>

      <h3 className="tw-font-sans tw-text-xl tw-mb-4 tw-text-white">
        Evidence Results For &quot;{recordingData.recording}&quot;
      </h3>

      <div className="tw-grid tw-gap-5 tw-grid-cols-[1fr_minmax(0,2fr)_1fr] tw-rounded-lg tw-text-white tw-w-auto">
        <div className="">
          <div className="tw-h-[35rem] tw-bg-surfaceBlack90 tw-rounded-lg tw-p-4 tw-text-white">
            <h3 className="tw-font-sans tw-text-lg tw-text-white"></h3>
          </div>
        </div>

        <div className="tw-grid tw-gap-5 tw-bg-surfaceBlack90 tw-rounded-lg tw-p-4 tw-pt-8 tw-text-white ">
          <div className="tw-flex tw-items-end tw-justify-end tw-gap-2 ">
            <WButton
              variant={isAtLeastOneChecked ? 'secondary' : 'disabled'}
              onClick={handleDownload}
              label="Download Selected"
            />
            <WButton
              variant={isAtLeastOneChecked ? 'primary' : 'disabled'}
              onClick={handleCopyToClipboard}
              label="Copy to Clipboard"
            />
          </div>
          <div className="tw-grid tw-grid-cols-[min-content_1fr_1fr_min-content] tw-gap-1.5 tw-items-center tw-align-top">
            <div className="tw-flex tw-items-center">
              {imagesSorted.length > 2 && (
                <WButton
                  onClick={() => {
                    setCarouselIndex(
                      (carouselIndex - 1 + imagesSorted.length) %
                        imagesSorted.length
                    ); // Move left circularly
                  }}
                  variant="link-secondary"
                  icon="chevron-left"
                  label=""
                />
              )}
            </div>
            {carouselImages.map((image, index) => (
              <div
                key={image.objectKey}
                className={clsx(
                  'tw-relative tw-aspect-square tw-h-full tw-flex tw-items-start tw-justify-start tw-max-h-[300px] tw-cursor-pointer'
                )}
              >
                {loading[image.objectKey] ? (
                  <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                    <p className="tw-my-auto tw-animate-ping">⚪</p>
                  </div>
                ) : (
                  <img
                    src={imageUrls[image.objectKey]}
                    onClick={() => {
                      handleImageClick(image);
                    }}
                    className="tw-max-h-[300px]"
                    alt="Preview carousel"
                  />
                )}
                <div className="tw-flex tw-flex-row tw-gap-2 tw-absolute tw-top-2 tw-left-2">
                  <div className="tw-bg-black/70 tw-rounded-[4px] tw-flex tw-h-[20px]">
                    <WCheckBox
                      name={`checkbox-${image.objectKey}`}
                      checked={
                        urlIsCheckedMap[imageUrls[image.objectKey]] || false
                      }
                      onChange={() => handleChecked(imageUrls[image.objectKey])}
                      checkboxSize="sm" // Customize size if needed
                      extraClasses=""
                    />
                  </div>
                  <img
                    alt=""
                    src={Star}
                    className={clsx('tw-w-6', {
                      'tw-hidden': !image.isPreferred,
                    })}
                  />
                </div>
              </div>
            ))}

            <div className="tw-flex tw-items-center tw-align-middle">
              {imagesSorted.length > 2 && (
                <WButton
                  onClick={() => {
                    setCarouselIndex((carouselIndex + 1) % imagesSorted.length); // Move right circularly
                  }}
                  variant="link-secondary"
                  icon="chevron-right"
                  label=""
                />
              )}
            </div>
          </div>

          {/* Evidence Images Grid */}
          <div className="tw-grid tw-gap-3">
            <h3 className="tw-font-sans">Evidence Images</h3>
            <div className="tw-grid tw-grid-cols-6 tw-gap-3">
              {imagesSorted
                .slice(0, visibleRows * ROW_SIZE) // Dynamically limit the number of images
                .map((image) => (
                  <div
                    key={image.objectKey}
                    className="tw-aspect-square tw-relative"
                  >
                    {loading[image.objectKey] ? (
                      <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                        <p className="tw-my-auto tw-animate-ping">⚪</p>
                      </div>
                    ) : (
                      <img
                        src={imageUrls[image.objectKey]}
                        onClick={() => {
                          handleImageClick(image);
                        }}
                        className="tw-cursor-pointer"
                        alt="Preview grid"
                      />
                    )}
                    <img
                      alt=""
                      src={Star}
                      className={clsx(
                        'tw-absolute tw-top-0 tw-left-0 tw-m-0.5 tw-w-4',
                        {
                          'tw-hidden': !image.isPreferred,
                        }
                      )}
                    />
                  </div>
                ))}
            </div>
          </div>
          {visibleRows * ROW_SIZE < imagesSorted.length && (
            <div className="tw-ml-auto tw-flex tw-space-x-2">
              <WButton
                variant="outline-light"
                onClick={() =>
                  setVisibleRows(Math.ceil(imagesSorted.length / ROW_SIZE))
                } // Show all rows
                label="View All"
              ></WButton>

              <WButton
                variant="secondary"
                onClick={() => setVisibleRows((prev) => prev + 2)} // Increase visible rows
                label="View More"
              ></WButton>
            </div>
          )}
        </div>

        <div className="tw-overflow-hidden">
          <div className="tw-h-[35rem]">
            <div className="tw-h-full tw-flex tw-flex-col tw-gap-5">
              <div className="tw-flex-1 tw-bg-surfaceBlack90 tw-rounded-lg tw-p-4 tw-text-white">
                <h3 className="tw-font-sans tw-text-lg tw-text-white tw-mb-4">
                  Recording Data
                </h3>
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <div className="tw-p-1 tw-pt-2 tw-min-h-[50px] tw-border-l-4 tw-border-x-westburyBlue">
                    <h3 className="tw-font-sans">Band/artist</h3>
                    <p className="tw-font-light tw-text-sm">
                      {recordingData.artist}
                    </p>
                  </div>
                  <div className="tw-p-1 tw-pt-2 tw-min-h-[50px] tw-border-l-4 tw-border-x-westburyRed">
                    <h3 className="tw-font-sans">Recording</h3>
                    <p className="tw-font-light tw-text-sm">
                      {' '}
                      {recordingData.recording}
                    </p>
                  </div>
                  <div className="tw-p-1 tw-pt-2 tw-min-h-[50px] tw-border-l-4 tw-border-x-surfaceYellow">
                    <h3 className="tw-font-sans">Contributor</h3>
                    <p className="tw-font-light tw-text-sm">
                      {recordingData.contributor}
                    </p>
                  </div>
                  <div className="tw-p-1 tw-pt-2 tw-min-h-[50px] tw-border-l-4 tw-border-x-westburyGreen">
                    <h3 className="tw-font-sans">Roles</h3>
                    {recordingData.roles.map((role) => {
                      return (
                        <p className="tw-font-light tw-text-sm" key={role}>
                          {role}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="tw-h-[30%] tw-bg-surfaceBlack90 tw-rounded-lg tw-p-4 tw-text-white">
                <h3 className="tw-font-sans tw-text-lg tw-text-white">
                  {/* Featured Contributors */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
