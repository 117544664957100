import React, { useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { Link } from 'react-router-dom';

import { useClientId } from 'shared_components/context/client';
import { useApi } from 'shared_components/context';
import {
  WSidebarModal,
  WSidebarModalFooter,
} from 'shared_components/components/WModal/WSidebarModal';

import WContainer from 'shared_components/components/WContainer';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import z from 'zod';
import clsx from 'clsx';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WFormFooterSecondary } from 'shared_components/components/WForms/WFormFooterSecondary';
import WTable from 'shared_components/WTable/WTable';
import FullName from 'shared_components/components/FullName';
import { UserSettingsAccountStatusEnum } from 'shared_components/generated/client';
import { DeleteDeclinedArtistRunStatusStatusEnum } from 'shared_components/generated/admin';
interface EOIReviewCardProps {
  deleteTaskOnConfirmed: () => void;
  eoiReviewID: string;
  onComplete: () => void;
  cardEvent: () => void;
  isOpen: boolean;
}

function EOIReviewCard({
  deleteTaskOnConfirmed,
  eoiReviewID,
  onComplete,
  cardEvent,
  isOpen,
}: EOIReviewCardProps) {
  // get the DECLINE_CLIENT_FEATURE_ENABLED variable from env vars
  const DECLINE_CLIENT_FEATURE_ENABLED: boolean =
    process.env.DECLINE_CLIENT_FEATURE_ENABLED === 'true';

  const { adminApi: api } = useApi();
  const [editNotes, setEditNotes] = useState(false);
  const { throwError } = useWError();
  const [userStatus, setUserStatus] =
    useState<UserSettingsAccountStatusEnum | null>();
  const [onBoardLink, setOnBoardlink] = useState('');

  const [notes, setNotes] = useState('');

  const [isFormEditable, setIsFormEditable] = useState(false);
  const { clientId, clientEmail } = useClientId();
  const [runStatus, setRunStatus] =
    useState<DeleteDeclinedArtistRunStatusStatusEnum | null>(null);
  const [userNotFound, setUserNotFound] = useState(false);

  const artistSchema = z.object({
    firstName: z.string().nonempty(),
    middleName: z.string(),
    lastName: z.string().nonempty(),
    email: z.string().email(),
  });

  const hideEmail = function (email: string) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  };

  const [formValue, setFormValue] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: clientEmail,
    hiddenEmail: clientEmail && hideEmail(clientEmail),
  });

  useEffect(() => {
    setUserStatus(null);
    setEditNotes(false);
    fetchData();
  }, [clientEmail]);

  useEffect(() => {
    // reset form states
    setIsFormEditable(false);
  }, [isOpen]);

  const inputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const blurHandle = async (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
    }
  };

  const fetchData = () => {
    api
      .retrieveAdminInterestRegistration({ userId: clientId })
      .then((userSettings) => {
        setUserStatus(userSettings.accountStatus);
        setOnBoardlink(userSettings.accountCreationUrl!);
        setFormValue({
          firstName: userSettings.firstName ?? '',
          middleName: userSettings.middleName ?? '',
          lastName: userSettings.lastName ?? '',
          email: userSettings.email ?? '',
          hiddenEmail: userSettings.email ? hideEmail(userSettings.email) : '',
        });
        setNotes(userSettings.notes ?? '');
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          setUserNotFound(true);
          setUserStatus(UserSettingsAccountStatusEnum.Rejected);
        }
      });
  };

  const declineClient = () => {
    api
      .updateAdminInterestRegistration({
        userId: clientId,
        adminInterestRegistration: {
          accountStatus: UserSettingsAccountStatusEnum.Rejected,
        },
      })
      .then(() => {
        setUserStatus(UserSettingsAccountStatusEnum.Rejected);
      })
      .catch(() => {
        throwError(new WError('There was an error declining the client.'));
      });
  };

  const onBoardClient = () => {
    if (clientId === undefined) {
      return;
    }

    api
      .updateAdminInterestRegistration({
        userId: clientId,
        adminInterestRegistration: {
          firstName: formValue.firstName,
          middleName: formValue.middleName,
          lastName: formValue.lastName,
          accountStatus: UserSettingsAccountStatusEnum.Approved,
          notes,
        },
      })
      .then((response) => {
        setUserStatus(response.accountStatus);
        setOnBoardlink(response.accountCreationUrl!);
      })
      .catch(() => {
        throwError(new WError('There was an error onboarding the client.'));
      });
  };
  const confirmDecline = () => {
    if (DECLINE_CLIENT_FEATURE_ENABLED) {
      setRunStatus(DeleteDeclinedArtistRunStatusStatusEnum.InProgress);
      api
        .declineAdminInterestRegistration({
          userId: clientId!,
        })
        .then(() => {
          getDeleteDeclinedArtistRunStatus();
        })
        .catch((e) => {
          console.log(e);
          throwError(new WError('There was an error declining the client.'));
        });
    } else {
      onComplete();
      cardEvent();
    }
  };
  const getDeleteDeclinedArtistRunStatus = async () => {
    if (clientId === undefined) {
      return;
    }
    if (!isOpen) {
      return;
    }
    await api
      .retrieveDeleteDeclinedArtistRunStatus({ userId: clientId })
      .then(({ status }) => setRunStatus(status))
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          fetchData();
          if (userNotFound) {
            // if no user found, likely the user was already deleted
            setRunStatus(DeleteDeclinedArtistRunStatusStatusEnum.Succeeded);
          }
        }
      });
  };

  useEffect(() => {
    getDeleteDeclinedArtistRunStatus();
  }, [isOpen, clientId]);
  type RunStatusMap = {
    [STATUS in DeleteDeclinedArtistRunStatusStatusEnum]: string;
  };
  const runStatusMap: RunStatusMap = {
    IN_PROGRESS: 'Decline in progress',
    SUCCEEDED: 'Decline succeeded',
    FAILED: 'Decline failed',
    UNKNOWN: 'Decline status unknown',
  };

  const saveArtistDetails = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .updateAdminInterestRegistration({
        userId: clientId,
        adminInterestRegistration: {
          firstName: formValue.firstName,
          middleName: formValue.middleName,
          lastName: formValue.lastName,
          notes,
        },
      })
      .catch((error) => {
        const defaultErrorMessage = "Couldn't save artist details.";
        error.response
          .json()
          .then((errorJson) => {
            throwError(
              new WError(errorJson.non_field_errors ?? defaultErrorMessage)
            );
          })
          .catch(() => {
            new WError(defaultErrorMessage);
          });
      });
  };

  const saveNotes = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .updateAdminInterestRegistration({
        userId: clientId,
        adminInterestRegistration: { notes },
      })
      .catch(() => {
        throwError(new WError("Couldn't save notes."));
      });
  };

  const [isCopied, setIsCopied] = useState(false);
  const copyTextToClipboard = (text: string) => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);

    return navigator.clipboard.writeText(text);
  };

  // dummy data
  const records = [
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
    {
      name: 'Victoria Beckham',
      spotify: '47,123',
      recordings: '1430 Recordings',
    },
  ];
  const columnStyle: React.CSSProperties = {
    fontSize: '14px',
    fontWeight: '500',
    borderBottom: '1px solid #fff',
  };
  const rowStyle: React.CSSProperties = {
    fontSize: '14px',
    fontWeight: '300',
    borderBottom: '1px solid #535D66',
  };

  return (
    <WSidebarModal title="" isOpen={isOpen} cardEvent={cardEvent}>
      <WFormProvider
        editByDefault={isFormEditable}
        formData={formValue}
        handleSubmit={saveArtistDetails}
        schema={artistSchema}
      >
        <WContainer>
          <div className="tw-flex tw-justify-between">
            <span className="tw-text-xl tw-mb-6">Artist details</span>

            <FormEditSection />
          </div>

          <FullName
            editedSettings={{
              firstName: formValue.firstName,
              lastName: formValue.lastName,
              middleName: formValue.middleName,
            }}
            handleInputChange={inputChange}
          />

          <WInput
            type="text"
            name="email"
            onChange={() => {}}
            onBlur={() => {}}
            value={formValue.email}
            label="Email"
            disabled={true}
          />
          <WFormFooterSecondary />
        </WContainer>
      </WFormProvider>

      {userStatus === UserSettingsAccountStatusEnum.Approved ? (
        <WContainer extraClasses="tw-border-[1px] tw-border-[#6EE038]">
          <div className="detailsBoxWrap">
            <p className="tw-text-xl tw-mb-2">
              <span>Artist Onboarded</span>
            </p>
            <p>Send this link to the client so they can create their account</p>
            <p className="tw-max-w-[400px]">
              <a className="tw-text-textLime tw-break-words" href={onBoardLink}>
                {onBoardLink}
              </a>
              {isCopied ? (
                <span className="d-block copied-text">
                  Copied text on clipboard
                </span>
              ) : (
                ''
              )}
            </p>
            <div className="tw-mt-4">
              <WButton
                variant="secondary"
                icon="copy"
                onClick={() => {
                  copyTextToClipboard(onBoardLink);
                }}
                label="Copy Link"
              />
            </div>
          </div>
        </WContainer>
      ) : userStatus === UserSettingsAccountStatusEnum.Rejected ? (
        <WContainer extraClasses="tw-border-[1px] tw-border-[#FF2600]">
          <div className="detailsBoxWrap">
            <p className="d-flex tw-text-xl tw-mb-4">
              <span>
                {' '}
                <i
                  className="wi wi-20px wi-alert-warning tw- tw-mr-2"
                  style={{ background: '#FF2600' }}
                ></i>
                Artist declined
              </span>
            </p>
            <p className="tw-max-w-[400px] tw-mb-4 tw-font-light">
              You will need to contact the client to inform them that their
              application has not been successful.
            </p>
          </div>
        </WContainer>
      ) : (
        <div className="">
          <WContainer>
            <p className="tw-text-xl tw-mb-6">PPL review</p>
            <Link to={`/discography-registrations/${eoiReviewID}`}>
              <WButton label="View review" variant="secondary" />
            </Link>
          </WContainer>
          <WContainer disabled title="Associated Artists">
            <div className="detailsBoxWrap">
              <p className="tw-text-xl tw-mb-6">Associated Artists</p>
              <div className="tw-max-h-[350px] tw-overflow-y-scroll">
                <WTable
                  columns={[
                    { title: 'Artist Name', width: '1fr', dataKey: 'name' },
                    { title: 'Spotify', width: '1fr', dataKey: 'spotify' },
                    {
                      title: 'Recordings',
                      width: '1fr',
                      dataKey: 'recordings',
                    },
                  ]}
                  columnStyle={columnStyle}
                  rowStyle={records.map(() => rowStyle)}
                  data={records}
                />
              </div>
            </div>
          </WContainer>
          <WContainer>
            <div className="detailsBoxWrap">
              <p className="tw-text-xl tw-mb-6">Notes</p>
              <div className="d-flex mb-3 scInputFields fonmGroupControl">
                <textarea
                  name="notes"
                  rows={10}
                  cols={20}
                  value={notes}
                  disabled={editNotes}
                  onChange={(e) => setNotes(e.target.value)}
                  className={clsx(
                    'tw-bg-formInputBackground',
                    'tw-resize-none',
                    'tw-text-gray-400',
                    'tw-rounded-lg',
                    'tw-w-full',
                    'tw-w-[450px]',
                    'tw-placeholder-textSeal',
                    'tw-text-sm',
                    'tw-leading-5',
                    'tw-p-3',
                    'tw-mb-2',
                    'tw-border-2',
                    'tw-border-formInputBackground',
                    'focus:tw-border-white',
                    'focus:tw-text-white',
                    'focus:tw-outline-none'
                  )}
                />
              </div>
              <div className="d-flex  justify-content-start">
                {editNotes ? (
                  <WButton
                    variant="secondary"
                    label="Edit"
                    icon="edit"
                    onClick={() => {
                      saveNotes();
                      setEditNotes(false);
                    }}
                  />
                ) : (
                  <WButton
                    variant="secondary"
                    label="Save"
                    onClick={() => {
                      setEditNotes(true);
                    }}
                  />
                )}
              </div>
            </div>
          </WContainer>
          <WSidebarModalFooter>
            <WButton
              variant="primary"
              onClick={onBoardClient}
              label="Onboard client"
            ></WButton>
            <WButton
              label="Decline client"
              onClick={() => declineClient()}
              variant="secondary"
            ></WButton>
          </WSidebarModalFooter>
        </div>
      )}

      {(userStatus === UserSettingsAccountStatusEnum.Approved ||
        userStatus === UserSettingsAccountStatusEnum.Rejected) && (
        <WSidebarModalFooter>
          <>
            {userStatus === UserSettingsAccountStatusEnum.Approved && (
              <WButton
                variant="primary"
                onClick={deleteTaskOnConfirmed}
                label="Complete Onboarding"
              />
            )}
            <div>
              {userStatus === UserSettingsAccountStatusEnum.Rejected && (
                <WButton
                  variant="primary"
                  onClick={confirmDecline}
                  label="Confirm Decline"
                  disabled={
                    !DECLINE_CLIENT_FEATURE_ENABLED || runStatus !== null
                  }
                />
              )}
              {runStatus !== null && (
                <div className="tw-my-4">
                  <p className="">
                    {
                      runStatusMap[
                        runStatus ??
                          DeleteDeclinedArtistRunStatusStatusEnum.Unknown
                      ]
                    }
                  </p>
                </div>
              )}
            </div>
          </>
        </WSidebarModalFooter>
      )}
    </WSidebarModal>
  );
}

function FormEditSection() {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  const { handleFormSubmit, formData } = useFormContext();
  return (
    <>
      {' '}
      {!isEditing && (
        <WButton
          label="Edit"
          variant="secondary"
          icon="edit"
          onClick={() => {
            setIsEditing(true);
          }}
        />
      )}
    </>
  );
}

export default EOIReviewCard;
