import React from 'react';

import logo from 'shared_components/public/assets/Logo.png';
import { Link } from 'react-router-dom';
import { useUser } from 'shared_components/context/user';
import { useSelectedDashboardPath } from 'shared_components/context/navigation';
import { WLink } from 'shared_components/components/WLink/WLink';
import clsx from 'clsx';
interface SidebarProps {
  sidebarToggle: boolean;
  setToggle: (isToggled: boolean) => void;
}

function Sidebar(props: SidebarProps) {
  const user = useUser();
  const selectedDashboardPath = useSelectedDashboardPath();
  const currentPath = location.pathname;
  const isDashboardPage = new RegExp(`^${selectedDashboardPath}$`).test(
    currentPath
  );

  const classes = clsx(
    {
      'tw-text-textLime': isDashboardPage,
    },
    {
      'tw-text-textWhite': !isDashboardPage,
    },
    'tw-cursor-pointer',
    'tw-hover:text-textLime'
  );

  const discographyAdditionalPaths = ['registrations', 'discography'];
  const settingsAdditionalPaths = ['society-config'];

  return (
    <div className="tw-text-white tw-border-r-outlineGraphite">
      <div className="">
        <div className="">
          <div className="tw-pb-8">
            <img src={logo} alt="Westbury Music Logo" width="151" />
          </div>

          <div className="tw-flex tw-flex-col tw-space-y-6 ">
            <Link to={`${selectedDashboardPath}`}>
              <div className="tw-flex tw-items-center tw-space-x-2">
                <i
                  className={clsx(
                    'wi',
                    'wi-sm',
                    'wi-home',
                    {
                      'tw-text-textLime': isDashboardPage,
                    },
                    {
                      'tw-text-textWhite': !isDashboardPage,
                    },
                    'tw-cursor-pointer'
                  )}
                ></i>
                <span className={classes}>Dashboard</span>
              </div>
            </Link>
            <WLink label="Clients" to="/clients" icon="users"></WLink>
            <WLink
              label="Discography"
              to="/discography"
              icon="speakers"
              additionalPaths={discographyAdditionalPaths}
            ></WLink>

            <WLink
              label="Distribution"
              to="/distribution"
              icon="gbp"
              variant="disabled"
            />
            <div className="tw-border-t tw-border-textGraphite tw-pt-8 tw-space-x-8">
              <WLink
                label="Settings"
                to="/settings"
                icon="settings"
                additionalPaths={settingsAdditionalPaths}
              />
              <WLink
                label="Messages"
                to="/messages"
                icon="email"
                variant="disabled"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
