import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import clsx from 'clsx';

import DataTable from 'react-data-table-component';
import { useApi } from 'shared_components/context';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import {
  AdminPersonalInformation,
  PrimaryDiscographyRequestSortFieldEnum,
  PrimaryDiscographyRequestSortOrderEnum,
} from 'shared_components/generated/admin';
import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WError } from 'shared_components/components/WError/WError';
import PageLayout from './PageLayout';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';

interface Row {
  id: string;
  artist: string;
  title: string;
  contribution: string;
  isrcs: string;
  year?: string | null;
  release: string;
  catNumbers: string;
  label: string;
  format: string;
  mediaType: string;
  stageName: string;
  isFeatured: boolean;
  isLive: boolean;
  location: string;
  source: string;
  // notes: string;
  earliestReleaseImageKey?: string;
  releaseImageKeys: string[];
}

type RowIdImageUrlMap = {
  [id: string]: string;
};

type PageSortArrayMap = {
  [key: number]: Array<any> | undefined;
};

type SortFieldSortOrderMap = {
  [FIELD in PrimaryDiscographyRequestSortFieldEnum]: PrimaryDiscographyRequestSortOrderEnum | null;
};

type RouteParams = {
  userId: string;
};

function MasterDiscography() {
  const { userId } = useParams<RouteParams>();
  const { adminApi: api } = useApi();
  const { throwError } = useWError();

  const [clientPersonalInfo, setClientPersonalInfo] =
    useState<AdminPersonalInformation>();
  const [isPendingExportGeneration, setIsPendingExportGeneration] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState(false);
  const [rows, setRows] = useState<Row[]>([]);
  const [images, setImages] = useState<RowIdImageUrlMap>({});

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [recordingsCount, setRecordingsCount] = useState<number>(0);
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false);

  const [isOpenExportConfirmationModal, setIsOpenExportConfirmationModal] =
    useState<boolean>(false);

  const handleChangePage = (newPage: number) => {
    if (newPage > page && newPage !== page + 1) {
      skipToLastPage();
      return;
    }
    setPage(newPage);
  };

  const skipToLastPage = async () => {
    if (userId === undefined) {
      return;
    }

    setIsLoading(true);

    const lastPage = Math.ceil(recordingsCount / rowsPerPage);
    let pageCounter = page + 1;
    let tempPageSortArrayMap = pageSortArrayMap;

    while (true) {
      const discographyData = await api.searchPrimaryDiscography({
        userId,
        primaryDiscographyRequest: {
          size: rowsPerPage,
          sortField,
          sortOrder,
          searchAfter: tempPageSortArrayMap[pageCounter - 1],
        },
      });

      tempPageSortArrayMap = {
        ...tempPageSortArrayMap,
        [pageCounter]: discographyData.sortArray,
      };

      if (pageCounter === lastPage - 1) {
        setPageSortArrayMap({
          ...tempPageSortArrayMap,
          [pageCounter]: discographyData.sortArray,
        });
        setPage(pageCounter);
        break;
      }

      pageCounter++;
    }
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setResetPaginationToggle(true);
  };

  const [sortField, setSortField] =
    useState<PrimaryDiscographyRequestSortFieldEnum>(
      'parentRecording.recordingTitle'
    );
  const [sortOrder, setSortOrder] =
    useState<PrimaryDiscographyRequestSortOrderEnum>('asc');
  const [pageSortArrayMap, setPageSortArrayMap] = useState<PageSortArrayMap>({
    0: undefined,
  });
  const [sortFieldSortOrderMap, setSortFieldSortOrderMap] =
    useState<SortFieldSortOrderMap>({
      'parentRecording.mainArtists': null,
      'parentRecording.recordingTitle': 'asc',
      'earliestParentRelease.releaseTitle': null,
      'earliestParentRelease.dateReleased': null,
      'earliestParentRelease.mediaType': null,
      'earliestParentRelease.format': null,
      'persona.stageName': null,
    });

  const handleSort = (newSortField: PrimaryDiscographyRequestSortFieldEnum) => {
    let newSortOrder: PrimaryDiscographyRequestSortOrderEnum;
    if (newSortField === sortField) {
      newSortOrder =
        sortFieldSortOrderMap[sortField] === 'asc' ? 'desc' : 'asc';
    } else {
      newSortOrder = 'asc';
    }
    setSortFieldSortOrderMap({
      ...sortFieldSortOrderMap,
      [sortField]: null,
      [newSortField]: newSortOrder,
    });
    setSortField(newSortField);
    setSortOrder(newSortOrder);
    setPage(1);
  };

  const ColumnHeading: React.FC<{
    sortField: PrimaryDiscographyRequestSortFieldEnum;
    children: React.ReactNode;
  }> = ({ sortField, children }) => {
    return (
      <button
        onClick={() => handleSort(sortField)}
        className="tw-flex tw-items-center tw-gap-0.5 hover:tw-text-neutral-200"
      >
        {children}
        <SortIcon sortOrder={sortFieldSortOrderMap[sortField]} />
      </button>
    );
  };

  const fetchData = async () => {
    if (userId === undefined) {
      return;
    }

    setIsLoading(true);

    api.retrieveAdminPersonalInformation({ userId }).then((personalInfo) => {
      setClientPersonalInfo(personalInfo);
    });

    try {
      const discographyData = await api.searchPrimaryDiscography({
        userId: userId,
        primaryDiscographyRequest: {
          size: rowsPerPage,
          sortField,
          sortOrder,
          searchAfter: pageSortArrayMap[page - 1],
        },
      });

      const rows = discographyData.recordings.map((recording) => {
        const {
          parentRecording,
          earliestParentRelease,
          evidenceReleases,
          westburyContributions,
          performance,
          persona,
        } = recording;
        return {
          id: parentRecording.id,
          artist: (parentRecording.mainArtists ?? []).join(', '),
          title: parentRecording.recordingTitle,
          release: earliestParentRelease?.releaseTitle ?? '',
          year: earliestParentRelease?.dateReleased,
          isrcs: parentRecording.isrcs?.toString() ?? '',
          contribution: westburyContributions
            .map((westburyContribution) => westburyContribution.contribution)
            .join(','),
          catNumbers: earliestParentRelease?.catNumbers?.toString() ?? '',
          label: earliestParentRelease?.releaseLabels?.toString() ?? '',
          format: earliestParentRelease?.format ?? '',
          mediaType: earliestParentRelease?.mediaType ?? '',
          stageName: persona.stageName,
          isFeatured: performance.featuredStatus === 'featured',
          isLive: parentRecording.live,
          location: parentRecording.countryOfRecording ?? '',
          source: recording.parentRecording.source.toString(),
          // notes: '',
          earliestReleaseImageKey: (
            evidenceReleases?.at(0)?.evidenceImages ?? []
          )
            .map((image) => image.objectKey)
            .at(0),
          releaseImageKeys: (evidenceReleases ?? []).flatMap((release) =>
            (release.evidenceImages ?? []).map((image) => image.objectKey)
          ),
        };
      });

      setRows(rows);
      setPageSortArrayMap({
        ...pageSortArrayMap,
        [page]: discographyData.sortArray,
      });
      setRecordingsCount(discographyData.count);
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    setResetPaginationToggle(false);
  }, [userId, page, rowsPerPage, sortField, sortOrder]);

  const fetchSignedEvidenceImageUrls = () => {
    if (userId === undefined) {
      return;
    }
    for (const row of rows) {
      if (row.earliestReleaseImageKey === undefined) {
        continue;
      }
      api
        .evidenceImageSignedUrlEvidenceImageSignedUrl({
          userId,
          evidenceImageSignedUrlRequest: {
            objectKey: row.earliestReleaseImageKey,
          },
        })
        .then(({ signedUrl }) => {
          setImages((prev) => {
            return { ...prev, [row.id]: signedUrl };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  useEffect(() => {
    fetchSignedEvidenceImageUrls();
  }, [rows]);

  const fetchLatestExportStatus = async () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveLatestPrimaryDiscographyExport({ userId })
      .then((primaryDiscographyExport) => {
        setIsPendingExportGeneration(!primaryDiscographyExport.ready);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchLatestExportStatus();
  }, [userId]);

  const handleGenerateExport = () => {
    if (userId === undefined) {
      return;
    }
    api
      .createGeneratePrimaryDiscographyExport({ userId })
      .then(() => {
        setIsOpenExportConfirmationModal(true);
        fetchLatestExportStatus();
      })
      .catch(() => {
        throwError(new WError('Discography export generation request failed'));
      });
  };

  const columns = [
    {
      name: 'Evd',
      cell: (row: Row) => (
        <div className="tw-w-[45px] tw-h-[45px]">
          {row.earliestReleaseImageKey ? (
            <Link to={`/discography/${userId}/ai-evidence/${row.id}`}>
              <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
                {images[row.id] ? (
                  <img
                    src={images[row.id]}
                    alt="Evidence"
                    className="tw-w-full tw-h-full"
                  />
                ) : (
                  <div className="tw-flex tw-justify-center">
                    <p className="tw-my-auto tw-animate-ping">⚪</p>
                  </div>
                )}
              </div>
            </Link>
          ) : (
            <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
              <i className="wi wi-shut-eye wi-20px" />
            </div>
          )}
        </div>
      ),
      minWidth: '80px',
      maxWidth: '80px',
    },
    {
      width: '128px',
      name: (
        <ColumnHeading sortField="parentRecording.mainArtists">
          Artist
        </ColumnHeading>
      ),
      selector: (row: Row) => row.artist,
    },
    {
      width: '128px',
      name: (
        <ColumnHeading sortField="parentRecording.recordingTitle">
          Recording Title
        </ColumnHeading>
      ),
      selector: (row: Row) => row.title,
    },
    {
      width: '128px',
      name: 'Contrib(s)',
      cell: (row: Row) => (
        <span className="tw-capitalize tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
          {row.contribution}
        </span>
      ),
      selector: (row: Row) => row.contribution,
    },
    {
      width: '128px',
      name: 'ISRC(s)',
      selector: (row: Row) => row.isrcs,
    },
    {
      width: '80px',
      name: (
        <ColumnHeading sortField="earliestParentRelease.dateReleased">
          Year
        </ColumnHeading>
      ),
      selector: (row: Row) =>
        row.year ? new Date(row.year).getFullYear() : '',
    },
    {
      width: '128px',
      name: (
        <ColumnHeading sortField="earliestParentRelease.releaseTitle">
          Release
        </ColumnHeading>
      ),
      selector: (row: Row) => row.release,
    },
    {
      width: '128px',
      name: 'Catalog No',
      selector: (row: Row) => row.catNumbers,
    },
    {
      width: '128px',
      name: 'Label',
      selector: (row: Row) => row.label,
    },
    {
      width: '100px',
      name: (
        <ColumnHeading sortField="earliestParentRelease.format">
          Format
        </ColumnHeading>
      ),
      selector: (row: Row) => row.format,
    },
    {
      width: '110px',
      name: (
        <ColumnHeading sortField="earliestParentRelease.mediaType">
          Type
        </ColumnHeading>
      ),
      selector: (row: Row) => row.mediaType,
    },
    {
      width: '128px',
      name: (
        <ColumnHeading sortField="persona.stageName">Stage Name</ColumnHeading>
      ),
      selector: (row: Row) => row.stageName,
    },
    {
      width: '60px',
      name: 'Ft',
      cell: (row: Row) => (
        <div>
          <i
            className={clsx('wi wi-xs', {
              'wi-check': row.isFeatured,
              'wi-close': !row.isFeatured,
            })}
          />
        </div>
      ),
      selector: (row: Row) => row.isFeatured,
    },
    {
      width: '80px',
      name: 'Live',
      cell: (row: Row) => (
        <div>
          <i
            className={clsx('wi wi-xs', {
              'wi-check': row.isLive,
              'wi-close': !row.isLive,
            })}
          />
        </div>
      ),
      selector: (row: Row) => row.isLive,
    },
    {
      width: '128px',
      name: 'Country of Recording',
      selector: (row: Row) => row.location,
    },
    {
      width: '128px',
      name: 'Source',
      selector: (row: Row) => row.source,
    },
    // Phase II
    // {
    //   width: '128px',
    //   name: 'Notes',
    //   selector: (row: Row) => row.notes,
    //
    // },
  ];

  const customStyles = {
    headRow: {
      style: {
        borderBottom: 'none !important',
        borderBottomColor: 'none !important',
        fontSize: '14px',
      },
    },
    headCells: {
      style: {
        background: '#151719',
        color: '#fff',
        paddingBottom: '14px',
        fontWeight: '500',
      },
    },
    cells: {
      style: {
        background: '#151719',
        color: '#fff',
        fontWeight: '300',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        minHeight: '60px',
        borderBottom: '1px solid #535D66 !important',
      },
    },
    pagination: {
      style: {
        color: '#fff',
        fontSize: '13px',

        backgroundColor: '#151719',
      },
      pageButtonsStyle: {
        color: '#fff',
        fill: '#fff',
        backgroundColor: 'transparent',
        '&:hover:not(:disabled)': {
          color: '#fff',
          fill: '#fff',
          backgroundColor: '#151719',
        },
        '&:active': {
          color: '#fff',
          fill: '#fff',
          backgroundColor: '#151719',
        },
        '&:disabled': {
          cursor: 'unset',
          color: '#535D66',
          fill: '#535D66',
        },
      },
    },
  };

  return (
    <PageLayout>
      <ExportConfirmationModal
        isOpen={isOpenExportConfirmationModal}
        onClose={() => setIsOpenExportConfirmationModal(false)}
      />
      {/* Back Button */}
      <div className="tw-flex tw-mb-6">
        <WButton
          variant="link-secondary"
          icon="chevron-left"
          onClick={() => {
            window.history.back();
          }}
          label="Back"
        />
      </div>
      <div>
        <h2>{`${clientPersonalInfo?.firstName} ${clientPersonalInfo?.lastName}`}</h2>
      </div>
      <div className="tw-grid tw-mt-5 mb-3">
        <div className="tw-justify-self-end">
          <WButton
            variant="secondary"
            label="Export Discography"
            icon="export"
            disabled={isPendingExportGeneration}
            onClick={handleGenerateExport}
          />
        </div>
      </div>
      <WContainer>
        {rows.length > 0 ? (
          <DataTable
            columns={columns}
            data={rows}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="7000px" // around 100 rows
            pagination
            paginationServer
            paginationTotalRows={recordingsCount}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            paginationResetDefaultPage={resetPaginationToggle}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          <div>
            {isLoading ? (
              <div className="tw-flex tw-justify-center">
                <p className="tw-my-auto tw-animate-ping">⚪</p>
              </div>
            ) : (
              <div className="loadingError">
                <h5>
                  {loadingError
                    ? 'Error loading data'
                    : 'No data available currently'}
                </h5>
              </div>
            )}
          </div>
        )}
      </WContainer>
    </PageLayout>
  );
}

const SortIcon: React.FC<{
  sortOrder: PrimaryDiscographyRequestSortOrderEnum | null;
}> = ({ sortOrder }) => {
  if (!sortOrder) {
    return <span className="tw-text-xs">&#8693;</span>;
  }

  return sortOrder === 'asc' ? <span>&#8673;</span> : <span>&#8675;</span>;
};

interface ExportConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportConfirmationModal: React.FC<ExportConfirmationModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <WModal isOpen={isOpen} onClose={onClose} title="" hasCloseButton={true}>
      <div className="tw-pb-8">
        <ThankyouBlock
          title="Discography Export In Progress"
          description="Once complete, a download link will appear as a task on your
          dashboard."
        />
      </div>
    </WModal>
  );
};

export default MasterDiscography;
